$items: 4;
$transition-time: 1s;
$animation-time: $transition-time * $items;
$scale: 20%;

$total-time: ($animation-time * $items);
$scale-base-1: (1 + $scale / 100%);
$brand-gray: rgb(100, 100, 100);

:export {
  itemCount: $items;
}

.slideshow {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $items;
    background-color: black;
    opacity: 0.3;
  }
}

.slideshow-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-color: $brand-gray;
  background-size: cover;
  animation-name: kenburns;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: $total-time;
  opacity: 1;
  transform: scale($scale-base-1);

  @for $i from 1 through $items {
    &.img-#{$i} {
      animation-name: kenburns-#{$i};
      z-index: ($items - $i);
      background-image: url('../images/slideshow/header_#{$i - 1}.jpg');
    }
  }
}

@for $i from 1 through $items {
  @keyframes kenburns-#{$i} {
    $animation-time-percent: percentage($animation-time / $total-time);
    $transition-time-percent: percentage($transition-time / $total-time);

    $t1: ($animation-time-percent * ($i - 1) - $transition-time-percent / 2);
    $t2: ($animation-time-percent * ($i - 1) + $transition-time-percent / 2);
    @if($t1 < 0%) { $t1: 0%; }
    @if($t2 < 0%) { $t2: 0%; }

    $t3: ($animation-time-percent * ($i) - $transition-time-percent / 2);
    $t4: ($animation-time-percent * ($i) + $transition-time-percent / 2);
    @if($t3 > 100%) { $t3: 100%; }
    @if($t4 > 100%) { $t4: 100%; }

    $t5: (100% - $transition-time-percent / 2);
    $t6: (($t4 - $t1) * 100% / $t5);

    #{$t1} { opacity: 1; transform: scale($scale-base-1); }
    #{$t2} { opacity: 1; }
    #{$t3} { opacity: 1; }
    #{$t4} { opacity: 0; transform: scale(1); }

    @if($i != $items) {
      100% { opacity: 0; transform: scale($scale-base-1); }
    }

    @if($i == 1) {
      $scale-plus: ($scale * (100% - $t5) / $t4);
      $scale-plus-base-1: (1 + ($scale + $scale-plus) / 100%);

      #{$t5} { opacity: 0; transform: scale($scale-plus-base-1); }
      100% { opacity: 1; }
    }
  }
}
