$brand-white: #ffffff;

.white-popup {
  position: relative;
  background: $brand-white;
  padding: 40px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  transition: 1s all;
}

.mfp-bg {}

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}


/* overlay animate in */

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}


/* overlay animate out */

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.4s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}